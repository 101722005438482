/*=========================================================================================
	File Name: horizontal-menu.scss
	Description: A classic horizontal menu for easy navingation & support all devices.
	It support light & dark version, filpped layout, right side icons, borders menu for
	item seperation.
	----------------------------------------------------------------------------------------
	Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard TemplateTheme
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// Core variables and mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../bootstrap-extended/mixins";

// Core variables and mixins overrides
@import "../variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../variables/components-variables";

// Import first main menu mixin
@import "../mixins/main-menu-mixin";

// Horizontal Menu
//=========================
.horizontal-layout {
  &.navbar-floating {
    .app-content {
      padding-top: 9.75rem;
    }
  }

  .content {
    margin-left: 0;
    .content-wrapper {
      margin-top: 0 !important;
      min-height: calc(100vh - 13.75rem);
    }
  }
  footer {
    position: static;
    margin-left: 0;
  }

  .header-navbar {
    &.fixed-top {
      left: 0;
    }
    .brand-logo {
      background: url("../../../img/logo/logo.png") no-repeat;
      background-position: -65px -54px;
      height: 24px;
      width: 35px;
    }

    .navbar-nav.float-right {
      .nav-item:not(:last-child) {
        a.nav-link {
          margin-right: 4px;
        }
        .dropdown-notification {
          .nav-link {
            margin-right: 10px;
          }
        }
      }
    }
  }

  ul#main-menu-navigation > li {
    line-height: 1;
    &.active {
      .nav-link {
        background: linear-gradient(
          118deg,
          rgba($primary, 1),
          rgba($primary, 0.7)
        );
        box-shadow: 0px 0px 6px 1px rgba($primary, 0.6);
        color: $white !important;
        border-radius: 4px;
        .dropdown-menu {
          .active {
            background-color: $body-bg;
          }
        }
      }
    }
    &.nav-item {
      &:not(:last-child) .nav-link.dropdown {
        margin-right: 4px;
      }
    }
    .single-item {
      padding-left: 11px !important;
      padding-right: 11px !important;
      .nav-link {
        color: $body-color;
        padding: 0.75rem 1.25rem !important;
      }
    }
    .dropdown:not(.sub-menu) {
      padding-top: 0.965rem;
      padding-bottom: 0.965rem;
      &.nav-link {
        padding: 0.75rem 1.25rem;
      }
    }
    .item-content {
      color: $body-color;
      span {
        vertical-align: middle;
      }
    }

    .dropdown-text {
      span:not(.menu-icon) {
        vertical-align: bottom;
      }
    }

    .has-sub-arrow {
      position: absolute;
      right: 1rem;
      top: 15px;
    }

    .dropdown-menu {
      margin-left: 0;
      animation-duration: 0.3s;
      animation-fill-mode: both;
      animation-name: slideIn;
      li {
        &:focus {
          outline: none;
        }
      }
      .dropdown-item {
        transition: padding 0.35s ease 0s !important;
        line-height: 1;
        &:focus {
          outline: 0;
        }
        &.hover {
          padding-left: 25px !important;
          transition: padding 0.35s ease 0s !important;
          background-color: transparent;
          color: $body-color;
        }
        &.has-sub {
          &:focus {
            outline: 0;
          }
          &.hover {
            padding-left: 10px !important;
            background-color: $body-bg;
          }
        }
        &.active {
          background: $body-bg;
          span,
          svg {
            color: $primary !important;
            font-weight: 500;
          }
        }
      }

      .open {
        &.active {
          > a {
            color: $body-color;
            font-weight: normal;
          }
        }
      }
      .dropdown-item {
        font-size: 1rem;
        display: flex;
        align-items: center;
        &:not(.has-sub) {
          padding: 0.95rem 1.428rem;
        }
        &:hover {
          span,
          svg {
            color: $body-color;
          }
        }
        &.has-sub {
          padding: 0;
          .dropdown {
            padding: 0.95rem 1.4rem;
          }
        }
      }
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: $gray-300;
        border-radius: 20px;
      }

      &::-webkit-scrollbar-track {
        background: $body-bg;
        border-radius: 30px;
      }
    }
    > .dropdown a {
      display: flex;
      margin-right: 0.5rem;
      align-items: center;
    }
    &.hover .nav-link {
      background: $body-bg;
      border-radius: 4px;
    }
    .nav-item-wrapper {
      padding: 11px 0;
    }
    .dropdown.has-active-child {
      background-color: $body-bg;
    }

    .dropdown-menu {
      &[x-placement="left-start"],
      &[x-placement="left-start"] .dropdown-menu {
        left: -100%;
        right: 100%;
      }
    }
  }

  &.navbar-floating {
    &:not(.blank-page) {
      .app-content {
        padding-top: 9.75rem;
      }
    }
    .horizontal-menu-wrapper {
      background: linear-gradient(
        to bottom,
        rgba(248, 248, 248, 0.95) 44%,
        rgba(248, 248, 248, 0.46) 73%,
        rgba(255, 255, 255, 0) 100%
      );
      background-repeat: repeat-x;
      .navbar-horizontal.floating-nav {
        margin: 1.3rem 2.2rem 0;
      }
    }
  }
  &.dark-layout {
    .header-navbar {
      background: inherit !important;
    }
    .horizontal-menu-wrapper {
      background: linear-gradient(
        to bottom,
        rgba(37, 43, 71, 0.76) 44%,
        rgba(56, 53, 53, 0.46) 73%,
        rgba(255, 255, 255, 0) 100%
      );
      .header-navbar {
        background: $menu-dark-bg-color !important;
        &.navbar-horizontal {
          ul#main-menu-navigation > li:hover:not(.active) > a {
            background: $content-dark-bg;
          }
        }
      }
    }
  }

  &.navbar-sticky {
    .app-content {
      padding-top: 8.5rem;
    }
    .header-navbar {
      background-color: $body-bg;
      box-shadow: none;
    }
    .horizontal-menu-wrapper {
      .navbar-horizontal.header-navbar.fixed-top {
        left: 0;
        top: 62px;
        background-color: $white;
      }
    }
  }
  &.navbar-static {
    .app-content {
      padding-top: 2.5rem;
    }
    .header-navbar:not(.scrolling) {
      background-color: $body-bg;
      box-shadow: none;
      .navbar-wrapper {
        width: 100%;
      }
    }
    .horizontal-menu-wrapper {
      position: relative;
      .navbar-horizontal.header-navbar {
        background: $white;
        &.navbar-static-top {
          position: static;
          background: $white;
          left: 0;
        }
      }
    }
  }
  //---------------//
  // Navbar Header //
  //---------------//
  .horizontal-menu-wrapper {
    position: fixed;
    top: 62px;
    z-index: 990;
    width: 100%;
    height: 99px;
    .header-navbar {
      .navbar-container {
        padding-left: 1rem;
        padding-right: 1rem;
        width: 100%;
      }
      .navbar-header {
        display: none;
      }
    }
  }
  .header-navbar {
    background: $white;
    z-index: 999 !important;
    line-height: 1;
    // Navbar- Brand Center - Logo
    &.navbar-horizontal.floating-nav {
      width: calc(100vw - (100vw - 100%) - calc(#{$content-padding} * 2));
      background: $white;
    }
    .navbar-container {
      padding-left: #{$content-padding};
      padding-right: calc(#{$content-padding} - 1rem);
      border-radius: $border-radius;
    }
    &.navbar-fixed {
      position: fixed;
      width: 100%;
    }
    &.navbar-brand-center {
      .navbar-header {
        position: absolute;
        left: 50%;
        margin-left: -65px;
        padding: 0;
        z-index: 1000;
        .navbar-brand {
          display: flex;
          align-items: center;
          margin-right: 0;
          // Brand Logo Scss

          // Brand Text Scss
          .brand-text {
            color: $white;
            padding-left: 0;
            letter-spacing: 0.01rem;
            font-size: 1.57rem;
          }
        }
      }
    }

    &.navbar-horizontal {
      .nav-link {
        &.dropdown-toggle::after {
          left: 0.4rem;
        }
      }
      .dropdown-menu::before {
        display: none;
      }
      .dropdown-menu {
        min-width: 215px;
        border: none;
        min-height: 52px;
        .disabled {
          pointer-events: none !important;
          a {
            color: $gray-600;
          }
        }
        .dropdown-toggle::after {
          left: auto;
          position: absolute;
          top: 50%;
          margin-top: -7px;
          right: 1rem;
          content: "\e844" !important;
        }

        .dropdown-submenu {
          position: relative;
          &.openLeft {
            .dropdown-menu {
              left: auto;
              right: 100%;
            }
          }
          &.show {
            background: $body-bg;
          }
          & > .dropdown-menu {
            position: absolute;
            top: 0 !important;
            left: 100% !important;
            i {
              font-size: 0.75rem !important;
            }
          }
        }
      }
    }
  }
  &.menu-collapsed {
    #main-menu-navigation {
      .nav-item {
        > a span {
          display: none;
        }
      }
    }
  }
  .navigation-header {
    font-family: inherit;
    color: #929292;
    padding: 8px 20px;
    font-size: 1rem;
    text-transform: uppercase;
  }

  .navbar-dark {
    .nav-item {
      &.active > a {
        border-bottom: 2px solid $primary;
        background-color: #313c50;
      }
    }
  }
  // App related CSS for Horizontal
  .content.app-content .content-area-wrapper {
    margin-top: 2.2rem;
    .content-wrapper {
      height: calc(100vh - 16rem);
      height: calc(var(--vh, 1vh) * 100 - 16rem);
    }
  }
  &.chat-application .sidebar-content,
  &.email-application .content-area-wrapper .sidebar .email-app-sidebar,
  &.todo-application .content-area-wrapper .sidebar .todo-sidebar {
    height: calc(100vh - 16rem);
    height: calc(var(--vh, 1vh) * 100 - 16rem);
  }
  &.todo-application .content-area-wrapper .content-right .todo-task-list {
    height: calc(100vh - 19.5rem);
    height: calc(var(--vh, 1vh) * 100 - 19.5rem);
  }
  &.email-application .app-content .content-area-wrapper .email-user-list {
    height: calc(100vh - 23.3rem);
    height: calc(var(--vh, 1vh) * 100 - 23.3rem);
  }
  &.chat-application {
    .user-profile-sidebar {
      height: calc(100vh - 16.1rem);
      height: calc(var(--vh, 1vh) * 100 - 16.1rem);
      bottom: 4.1rem;
    }
    .chat-profile-sidebar {
      height: calc(100vh - 16rem);
      height: calc(var(--vh, 1vh) * 100 - 16rem);
    }
    .chat-app-window .user-chats {
      height: calc(100vh - 26.5rem);
      height: calc(var(--vh, 1vh) * 100 - 26.5rem);
    }
  }
}

// Initially menu & content width for md and down screen
@include media-breakpoint-down(lg) {
  body.horizontal-layout {
    &.horizontal-menu {
      .horizontal-menu-wrapper {
        .header-navbar {
          display: none;
        }
      }
    }
    .header-navbar {
      background: $white;
    }
    .content .content-wrapper {
      margin-top: 5rem;
    }
  }
}

@media (max-width: 1230px) and (min-width: 1200px) {
  .horizontal-layout {
    .horizontal-menu-wrapper {
      #main-menu-navigation {
        .nav-item .nav-link {
          a {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@keyframes slideIn {
  100% {
    transform: translateY(0);
    opacity: 1;
  }

  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}
